
import Paging from "@/components/Paging.vue";
import NewModal from "@/components/NewModal.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Vue,
  Watch,
} from "vue-property-decorator";

@Component({
  name: "InfoInquiry",
  props: {},
  components: { Paging, NewModal,Breadcrumbs },
})
export default class InfoInquiry extends Vue {
  photos: Array<string> = [];
  condtionVisible: boolean = false;
  zzlApi: any;
  zzlFormat: any;
  condition: any = {
    idNumber: "",
  };
  desc: string = ""; // 批量查询数据
  dialog: any = {
    visible: false,
    title: "批量查询",
    width: "500px",
    showFooter: true,
    cancelText: "重置",
    confirmText: "提交",
  };
  table: any = {
    pageNum: 1,
    pageSize: 20,
    total: 0,
  };
  tableConfigList: any[] = []; // 表格配置数据列表
  tableData: any = [];
  stars: any = "";
  currentVal: any = 1; // 查询条件接口值
  statusList: any = [
    {
      label: "全部",
      value: "",
      color: "",
    },
    {
      label: "有效",
      value: "F",
      color: "#7cb342",
    },
    {
      label: "过期",
      value: "L",
      color: "red",
    },
  ];
  isBatchSearch: any = false;
  tips: any = {
    total: 0,
    success: 0,
  };
  id_number_array: any = [];
  importExcelFile: any = [];
  exportValue: any = "";
  exportPageNum: any = 1; //导出页码
  exportPageSize: any = 5000; //导出单页数据条数
  flag: any = true;
  cancelledNum = 0;
  cancelledOther = "";
  indoorStaff = "";
  idArr: any = [];
  //computed
  //get example() {}
  //
  batchSearchAction() {
    this.dialog.visible = true;
  }
  // 批量查询确定
  async confirm() {
    if (this.desc === "") {
      this.$message.warning("查询条件不能为空");
      return;
    }
    let str = this.desc.trim();
    let arr = str.split(/[\s]|[,]+/);
    // let temp: any = [...new Set(arr)];
    //去重 去空
    let temp: any = [];
    arr.map((item: any, index: any) => {
      if (!temp.includes(item) && item !== "") {
        temp.push(item);
      }
    });
    if (temp.length > 1000) {
      this.$message.warning("查询条件不能超过1000");
      return;
    }
    this.id_number_array = temp;
    this.condition.idNumber="";
    await this.getList();
    this.close();
  }
  // 关闭弹框
  close() {
    this.dialog.visible = false;
  }
  // 重置
  cancel() {
    if (this.desc === "") {
      this.$message.warning("没有任何数据重置");
      return;
    }
    this.$confirm("此操作将重置当前输入数据，是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        this.desc = "";
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "已取消操作",
        });
      });
  }

  goPersonalFile(item: any) {
    const last_router: any = this.$route.name;
    window.sessionStorage.setItem("last_router", last_router);
    this.$router.push({ name: "PersonalFile" ,query:{memberId:item.id}}).catch(() => {});
  }

  searchAction() {
    let idNumber = this.condition.idNumber.trim();
    let flag = this.zzlFormat.validateIdCard(idNumber);
    if (idNumber === "") {
      this.$message.error("请输入证件号码");
      return;
    }
    this.searchData();
  }
  // 搜索数据
  searchData() {
    this.table.pageNum = 1;
    this.isBatchSearch = true;
    this.id_number_array = [];
    this.tips.total = 0;
    this.getList();
  }
  // 重置
  resetCondition() {
    this.condition.idNumber = "";
    this.id_number_array = [];
    this.tableData = [];
    this.table.total = 0;
    this.tips.total = 0;
  }
  async getList() {
    let idNumbers = [...this.id_number_array];
    if (this.condition.idNumber) {
      idNumbers.push(this.condition.idNumber);
    }
    let params = {
      ...this.table,
      by: 1,
      order: "entry_date",
      idNumbers: idNumbers,
    };
    const response = await this.zzlApi.member.List(params, true, true);
    if (response.code === 0) {
      this.tableData = response.data.list;
      this.table.total=response.data.count;
    } else {
      this.$message.error("查询错误，请联系管理员！");
    }
  }
  //lifecycle
  async mounted() {
  }
}
