
import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Vue,
  Watch,
} from "vue-property-decorator";

@Component({
  name: "NewDialog",
  props: {},
  components: {},
})
export default class NewDialog extends Vue {
  @Prop({
    type: Object,
    default: () => {
      return {
        visible: false,
        title: "dialog",
        width:'50%',
        top:'10vh',
        showFooter:true,
        cancelText:'取消',
        confirmText:'确定',
        showCancel:true
      };
    },
  })
  dialog!: any;
  //init data

  showCancel:boolean = true
  //computed
  //get example() {}
  //methods
  @Emit('on-cancel')
  cancelDialog(){}
  
  @Emit('on-close')
  closeDialog() {}

  @Emit('on-confirm')
  confirmDialog() {}
  //lifecycle
  mounted() {
    this.showCancel = this.dialog.showCancel !== false ?true:this.dialog.showCancel
  }
}
